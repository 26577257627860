import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import AdminCopyPrsContent from "./components/adminCopyPrsContent.js";

function AdminCopyPrs() {
  return (
    <div>
      <TitleHolder>Purchase Requisitions </TitleHolder>
      <StepperContentHolder>
        <AdminCopyPrsContent />
      </StepperContentHolder>
    </div>
  );
}

export default AdminCopyPrs;
