import apiClient from "../../../utilities/apiClient";
import LocalStorage from "../../../utilities/localStorage";

export const get_vendors = (payload) => {
  return apiClient.get(`${apiClient.Urls.vendors_list}`, payload, true);
};

export const approve_pr = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const roles = user.roles;
  const role = roles[0];
  return apiClient.patch(
    role !== "officer"
      ? `${apiClient.Urls.pr_approve_sa}/${payload.pr_id}`
      : `${apiClient.Urls.pr_approve}/${payload.pr_id}`,
    payload,
    true
  );
};

export const approve_item = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const roles = user.roles;
  const role = roles[0];
  return apiClient.patch(
    role !== "officer"
      ? `${apiClient.Urls.item_approve_sa}`
      : `${apiClient.Urls.item_approve}`,
    payload,
    true
  );
};

export const push_pr_to_pg = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const roles = user.roles;
  const role = roles[0];
  return apiClient.post(
    role === "officer"
      ? `${apiClient.Urls.officer_push_pr_to_pg}`
      : `${apiClient.Urls.admin_push_pr_to_pg}`,
    payload,
    true
  );
};

export const get_materials = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  var roles = user.roles;
  var role = roles[0];
  return apiClient.get(
    role !== "officer"
      ? `${apiClient.Urls.materials_list_sa}?item_name=${payload}`
      : `${apiClient.Urls.materials_list}?item_name=${payload}`,
    "",
    true
  );
};

export const get_vendor_emails = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.vendors_list}?vendor_id=${payload}`,
    "",
    true
  );
};

export const prs = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const roles = user.roles;
  const role = roles[0];
  return apiClient.post(
    role === "officer"
      ? `${apiClient.Urls.officer_prs}`
      : `${apiClient.Urls.admin_prs}`,
    payload,
    true,
    "file"
  );
};

export const view_AdminPrDetails = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const roles = user.roles;
  const role = roles[0];
  return apiClient.get(
    role !== "officer"
      ? `${apiClient.Urls.view_admin_pr_details_sa}/${payload}`
      : `${apiClient.Urls.view_admin_pr_details}/${payload}`,
    "",
    true
  );
};

export const view_AdminApprovedItemsPrDetails = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const roles = user.roles;
  const role = roles[0];
  return apiClient.get(
    role === "officer"
      ? `${apiClient.Urls.view_officer_approved_items_pr_details}/${payload}`
      : `${apiClient.Urls.view_admin_approved_items_pr_details}/${payload}`,
    "",
    true
  );
};

export const view_AdminItemDetails = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const roles = user.roles;
  const role = roles[0];
  return apiClient.get(
    role !== "officer"
      ? `${apiClient.Urls.view_admin_item_details_sa}/${payload}`
      : `${apiClient.Urls.view_admin_item_details}/${payload}`,
    "",
    true
  );
};

export const get_prs = (status, filter, page, per_page, search) => {
  var user = LocalStorage.getItem("tata_login_user");
  const roles = user.roles;
  const role = roles[0];
  return apiClient.get(
    role !== "officer"
      ? `${
          apiClient.Urls.prs_list_sa
        }?filter=${filter}&status=${status}&page=${page}&per_page=${per_page}&search=${
          search || ""
        }`
      : `${
          apiClient.Urls.prs_list
        }?filter=${filter}&status=${status}&page=${page}&per_page=${per_page}&search=${
          search || ""
        }`,
    "",
    true
  );
};

export const get_all_prs = () => {
  return apiClient.get(apiClient.Urls.all_prs, "", true);
};

export const copy_pr = (payload) => {
  var user = LocalStorage.getItem("tata_login_user");
  const roles = user.roles;
  const role = roles[0];
  return apiClient.post(
    role === "officer"
      ? `${apiClient.Urls.officer_copy_pr}`
      : `${apiClient.Urls.admin_copy_pr}`,
    payload,
    true
  );
};

export const add_excel_data = (payload) => {
  return apiClient.post(`${apiClient.Urls.add_excel_prs}`, payload, true);
};

export const PR_preview = (payload) => {
  return apiClient.get(`${apiClient.Urls.prDownalod}/${payload}`, "", true);
};
