// import { PAN_AGENCY_KEY, PAN_API_KEY } from "../CONSTANTS";

import { dev, domainName } from "./helper";

import LocalStorage from "./localStorage";

// export const apiUrl = "http://192.168.0.109:3000";
console.log("dev: ", dev);
export const apiUrl = dev ? "http://localhost:3000" : domainName;

export const panUrl = "https://preprod.aadhaarapi.com";

const encodeQueryData = (data = null) => {
  if (data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
    return "?" + ret.join("&");
  }
  return "";
};

export const getLocalToken = () => {
  const user = LocalStorage.getItem("tata_login_user");
  const authToken = user?.token;
  if (authToken !== null && authToken !== "") {
    return `${authToken}`;
  } else {
    return "";
  }
};

const apiClient = {
  Urls: {
    apiUrl,
    panUrl,
    signin: `${apiUrl}/api/signin`,
    signup: `${apiUrl}/api/signup`,
    vendor_reg: `${apiUrl}/api/vendor_reg`,
    contactSave: `${apiUrl}/api/contact`,
    create_new: `${apiUrl}/api/reset_password`,
    validate_pan_officer: `${apiUrl}/api/officer/validatePanOfficer`,
    vendor_reg_officer: `${apiUrl}/api/officer/vendor_reg_officer`,
    validate_gst_officer: `${apiUrl}/api/officer/gst-validate`,
    createuser: `${apiUrl}/api/officer/createUser`,
    resetPassword: `${apiUrl}/api/reset_password_link`,
    panVerification: `${panUrl}/pan`,
    goodsService: `${apiUrl}/api/goods_service`,
    categories: `${apiUrl}/api/goods_service/categories`,
    getItem: `${apiUrl}/api/goods_service/item`,
    subcategoriesOfficer: `${apiUrl}/api/officer/subCategories`,
    goodsServiceOfficer: `${apiUrl}/api/officer/GandS`,
    categoriesOfficer: `${apiUrl}/api/officer/categories`,
    getItemOfficer: `${apiUrl}/api/officer/item`,
    create_bank: `${apiUrl}/api/bank-information`,
    delete_bank: `${apiUrl}/api/bank-information/delete`,
    validate_gst: `${apiUrl}/api/validate-gst`,
    countries_get: `${apiUrl}/api/country`,
    states_get: `${apiUrl}/api/country/state`,
    add_business: `${apiUrl}/api/location`,
    add_business_officer: `${apiUrl}/api/officer/add-location`,
    add_bank_officer: `${apiUrl}/api/officer/add-bank`,
    bankinformation: `${apiUrl}/api/bank-information`,
    adminBankinformation: `${apiUrl}/api/admin/bank-information`,
    officerBankinformation: `${apiUrl}/api/vendor/officer/bank-information`,
    admindeleteBankinformation: `${apiUrl}/api/admin/bank-information/delete`,
    view_details: `${apiUrl}/api/vendor_reg/vendorLocation`,
    dash_board: `${apiUrl}/api/vendor_reg/vendorDetails`,
    vendor_reg_update: `${apiUrl}/api/vendor_reg/update`,
    pending_list: `${apiUrl}/api/vendor/officer/pending`,
    approved_list: `${apiUrl}/api/vendor/officer/approved`,
    view_admin_details: `${apiUrl}/api/vendor/vendorLocation`,
    view_officer_details: `${apiUrl}/api/vendor/officer/vendorLocation`,
    vendor_reg_upload: `${apiUrl}/api/vendor/upload`,
    item_upload: `${apiUrl}/api/item/upload`,
    vendor_approve: `${apiUrl}/api/vendor/update`,
    vendor_approves: `${apiUrl}/api/vendor/updates`,
    officer_vendor_approves: `${apiUrl}/api/officer/vendor-updates`,
    update_business: `${apiUrl}/api/location/update`,
    change_password: `${apiUrl}/api/change_password`,
    vendors_list: `${apiUrl}/api/vendor/officer/all-vendors`,
    orders: `${apiUrl}/api/order`,
    add_excel_orders: `${apiUrl}/api/vendor/officer/excel`,
    orders_list: `${apiUrl}/api/vendor/officer/orderlist`,
    invoices_list: `${apiUrl}/api/vendorDetails/invoice-list`,
    invoices: `${apiUrl}/api/vendorDetails/invoice/create-invoice`,
    order_numbers: `${apiUrl}/api/vendorDetails/orderlist`,
    gst_info: `${apiUrl}/api/vendorDetails/invoice/all-gsts`,
    location_info: `${apiUrl}/api/vendorDetails/invoice/all-locations`,
    view_order_details: `${apiUrl}/api/vendorDetails/orderbyid`,
    returned_list: `${apiUrl}/api/vendor/officer/rejected`,
    order_approve: `${apiUrl}/api/vendorDetails/acceptOrder`,
    officer_invoices_list: `${apiUrl}/api/vendor/officer/invoice-list`,
    view_invoice_details: `${apiUrl}/api/vendor/officer/Invoicebyid`,
    invoice_approve: `${apiUrl}/api/vendor/officer/acceptInvoice`,
    view_admin_order_details: `${apiUrl}/api/vendor/officer/orderbyid`,
    view_user_invoice_details: `${apiUrl}/api/vendorDetails/Invoicebyid`,
    subcategories: `${apiUrl}/api/goods_service/subCategories`,
    upload_gst_doc: `${apiUrl}/api/aws_s3`,
    order_accept_numbers: `${apiUrl}/api/vendorDetails/orderDropdowList`,
    company: `${apiUrl}/api/company`,
    company_list: `${apiUrl}/api/company/list`,
    item_list: `${apiUrl}/api/item/list`,
    company_lists: `${apiUrl}/api/company/lists`,
    users: `${apiUrl}/api/company/user`,
    add_users: `${apiUrl}/api/company/user/add`,
    users_details: `${apiUrl}/api/company/user/details`,
    role_list: `${apiUrl}/api/role`,
    invite_register_link: `${apiUrl}/api/activities/invite-link`,
    getALLVendorAdmin: `${apiUrl}/api/vendor/vendorList`,
    validateIFSC: `${apiUrl}/api/bank-information/validate-bank`,
    validate_ifsc_officer: `${apiUrl}/api/officer/validate-bank`,
    check_token_status: `${apiUrl}/api/token_status`,
    add_finance_globle: `${apiUrl}/api/globle-role/add-finance`,
    get_list: `${apiUrl}/api/globle-role/get-list`,
    all_officer_list: `${apiUrl}/api/globle-role/get-all-officer`,
    assign_acting_role: `${apiUrl}/api/globle-role/assign-acting-SA`,
    inactive_acting_role: `${apiUrl}/api/globle-role/inactive-acting-SA`,
    admin_order_list: `${apiUrl}/api/vendor/order-list`,
    admin_order_view: `${apiUrl}/api/vendor/order-view`,
    admin_invoice_lists: `${apiUrl}/api/vendor/invoice-list`,
    admin_invoice_view: `${apiUrl}/api/vendor/invoice-view`,
    check_tenant: `${apiUrl}/api/tenants`,
    company_officer: `${apiUrl}/api/company/officers`,
    user_companies: `${apiUrl}/api/company/user/list`,
    companyListDropdown: `${apiUrl}/api/company/dropdown`,
    activeInactiveCompany: `${apiUrl}/api/company/active`,
    subCompanyDropdown: `${apiUrl}/api/company/sub-company/dropdown`,
    getHsns: `${apiUrl}/api/item/hsn-list`,
    item: `${apiUrl}/api/item`,
    officer_subCompanyDropdown: `${apiUrl}/api/officer/company/sub-company/dropdown`,
    addUserInCompanies: `${apiUrl}/api/company/user/multiple`,
    roleListDropdown: `${apiUrl}/api/role`,
    officerCompnayListForDropdown: `${apiUrl}/api/officer/company`,
    admin_CompnayListForPr: `${apiUrl}/api/company/dropdown/pr/company`,
    officer_CompnayListForPr: `${apiUrl}/api/officer/dropdown/pr-company`,
    pushTosap: `${apiUrl}/api/sap/push`,
    fetchVendorcodeWithCompanyName: `${apiUrl}/api/vendor/vendor-code`,
    fetchVendorcodeWithCompanyNameForOfficer: `${apiUrl}/api/vendor/officer/vendor-code`,
    user_notification: `${apiUrl}/api/user-notification`,
    officer_notification: `${apiUrl}/api/officer-notification`,
    admin_notification: `${apiUrl}/api/admin-notification`,
    officer_vendor_count: `${apiUrl}/api/vendor/officer/count`,
    admin_vendor_count: `${apiUrl}/api/vendor/count`,
    materials_list: `${apiUrl}/api/vendor/officer/all-materials`,
    materials_list_sa: `${apiUrl}/api/workflows/super-admin/all-materials`,
    asn_list: `${apiUrl}/api/vendor/officer/all-asns`,
    grns: `${apiUrl}/api/grn`,
    add_excel_grns: `${apiUrl}/api/vendor/officer/excel`,
    grns_list: `${apiUrl}/api/vendor/officer/grnlist`,
    asns_list: `${apiUrl}/api/vendorDetails/asn-list`,
    schedules_list: `${apiUrl}/api/vendorDetails/asn-list`,
    inspections: `${apiUrl}/api/activity/inspection/send-link`,
    schedules: `${apiUrl}/api/vendorDetails/schedule/create-schedule`,
    schedule_numbers: `${apiUrl}/api/vendorDetails/schedulelist`,
    view_schedule_details: `${apiUrl}/api/vendorDetails/schedulebyid`,
    admin_invoices_list: `${apiUrl}/api/vendor/officer/invoice-list`,
    admin_asns_list: `${apiUrl}/api/vendor/officer/asn-list`,
    admin_schedules_list: `${apiUrl}/api/vendor/officer/schedule-list`,
    view_asn_details: `${apiUrl}/api/vendor/officer/Asnbyid`,
    view_admin_schedule_details: `${apiUrl}/api/vendor/officer/Schedulebyid`,
    asn_approve: `${apiUrl}/api/vendor/officer/acceptAsn`,
    view_admin_grn_details: `${apiUrl}/api/vendor/officer/grnbyid`,
    inspection_lot_numbers: `${apiUrl}/api/activity/inspection/lot-list`,
    get_lot_items_for_inspection: `${apiUrl}/api/activity/inspection/lot/item-list`,
    inspection_list: `${apiUrl}/api/activity/inspection/list`,
    view_UserInspectionDetails: `${apiUrl}/api/activity/inspection`,
    asns: `${apiUrl}/api/vendorDetails/asn/create-asn`,
    lots: `${apiUrl}/api/vendorDetails/lot/create-lot`,
    lot_numbers: `${apiUrl}/api/vendorDetails/orderlist`,
    grn_numbers: `${apiUrl}/api/vendorDetails/grnlist`,
    packaging_type_info: `${apiUrl}/api/vendorDetails/item/all-packaging-types`,
    view_lot_details: `${apiUrl}/api/vendorDetails/lotbyid`,
    view_grn_details: `${apiUrl}/api/vendorDetails/grnbyid`,
    edit_lot: `${apiUrl}/api/vendorDetails/updateLotStatus`,
    grn_approve: `${apiUrl}/api/vendorDetails/acceptOrder`,
    admin_lots_list: `${apiUrl}/api/vendor/officer/lot-list`,
    view_admin_lot_details: `${apiUrl}/api/vendor/officer/Lotbyid`,
    view_user_asn_details: `${apiUrl}/api/vendorDetails/Asnbyid`,
    view_user_schedule_details: `${apiUrl}/api/vendorDetails/Asnbyid`,
    lot_accept_numbers: `${apiUrl}/api/vendorDetails/lotDropdowList`,
    schedule_accept_numbers: `${apiUrl}/api/vendorDetails/scheduleDropdowList`,
    grn_accept_numbers: `${apiUrl}/api/vendorDetails/grnDropdowList`,
    get_items: `${apiUrl}/api/vendorDetails/items`,
    get_lot_items: `${apiUrl}/api/vendorDetails/lotitems`,
    get_schedule_items: `${apiUrl}/api/vendorDetails/scheduleitems`,
    get_grn_items: `${apiUrl}/api/vendorDetails/grnItems`,
    inspection_lot_details: `${apiUrl}/api/activity/inspection/show-data`,
    create_inspection: `${apiUrl}/api/activity/inspection`,
    get_pr_granted_users: `${apiUrl}/api/user-privileges/pr-granted-users`,
    get_pr_pending_users: `${apiUrl}/api/user-privileges/pr-pending-users`,
    get_pr_revoked_users: `${apiUrl}/api/user-privileges/pr-revoked-users`,
    grant_pr_privilege: `${apiUrl}/api/user-privileges/grant-pr-creation`,
    revoke_pr_privilege: `${apiUrl}/api/user-privileges/revoke-pr-creation`,
    view_admin_pr_details: `${apiUrl}/api/vendor/officer/prbyid`,
    officer_prs: `${apiUrl}/api/vendor/officer/pr`,
    admin_prs: `${apiUrl}/api/admin-workflow/pr`,
    officer_copy_pr: `${apiUrl}/api/vendor/officer/copy_pr`,
    admin_copy_pr: `${apiUrl}/api/admin-workflow/copy_pr`,
    prs_list: `${apiUrl}/api/vendor/officer/prlist`,
    all_prs: `${apiUrl}/api/vendor/officer/allprs`,
    add_excel_prs: `${apiUrl}/api/vendor/officer/excel-pr`,
    get_workflows: `${apiUrl}/api/workflows`,
    get_workflow_category_list: `${apiUrl}/api/admin-workflow/get-workflow-categories`,
    get_range_list: `${apiUrl}/api/admin-workflow/get-ranges`,
    view_workflow_details: `${apiUrl}/api/workflows`,
    workflow_steps: `${apiUrl}/api/workflows/steps`,
    get_approvers: `${apiUrl}/api/vendor/officer/steps/approvers`,
    admin_get_approvers: `${apiUrl}/api/admin-workflow/steps/approvers`,
    get_persons: `${apiUrl}/api/vendor/officer/steps/persons`,
    admin_get_persons: `${apiUrl}/api/admin-workflow/steps/persons`,
    departments: `${apiUrl}/api//departments/create-department`,
    departments_list: `${apiUrl}/api/departments`,
    pr_approve: `${apiUrl}/api/vendor/officer/pr/update`,
    pr_approve_sa: `${apiUrl}/api/workflows/super-admin/pr/update`,
    view_admin_pr_details_sa: `${apiUrl}/api/workflows/super-admin/prbyid`,
    prs_list_sa: `${apiUrl}/api/workflows/super-admin/prlist`,
    get_approvers_sa: `${apiUrl}/api/workflows/super-admin/steps/approvers`,
    get_persons_sa: `${apiUrl}/api/workflows/super-admin/steps/persons`,
    get_pr_creation_status: `${apiUrl}/api/vendor/officer/pr/status`,
    get_pr_creation_status_sa: `${apiUrl}/api/workflows/super-admin/pr/status`,
    view_pr_workflow_details: `${apiUrl}/api/vendor/officer/pr-creation`,
    admin_view_pr_workflow_details: `${apiUrl}/api/admin-workflow/pr-creation`,
    view_pr_workflow_details_sa: `${apiUrl}/api/workflows/super-admin/pr-creation`,
    item_approve: `${apiUrl}/api/vendor/officer/item/update`,
    item_approve_sa: `${apiUrl}/api/workflows/super-admin/item/update`,
    view_admin_item_details: `${apiUrl}/api/vendor/officer/itembyid`,
    view_admin_item_details_sa: `${apiUrl}/api/workflows/super-admin/itembyid`,
    workflows: `${apiUrl}/api//workflows/create-workflow`,
    get_purchase_points: `${apiUrl}/api/workflows/get-purchase-points`,
    officer_get_purchase_points: `${apiUrl}/api/vendor/officer/get-purchase-points`,
    view_officer_approved_items_pr_details: `${apiUrl}/api/vendor/officer/approved-items-prbyid`,
    view_admin_approved_items_pr_details: `${apiUrl}/api/admin-workflow/approved-items-prbyid`,
    officer_push_pr_to_pg: `${apiUrl}/api/vendor/officer/pushPRToPg`,
    admin_push_pr_to_pg: `${apiUrl}/api/admin-workflow/pushPRToPg`,
    check_workflow_step_are_defind: `${apiUrl}/api/officer/workflow/step-check`,
    downloadApprovedVendorExcel: `${apiUrl}/api/vendor/fetch-approved-list`,
    initiatedVendorData: `${apiUrl}/api/vendor/initiated`,
    OfficerInitiatedVendorData: `${apiUrl}/api/vendor/officer/initiated`,
    prDownalod: `${apiUrl}/api/prs/audit-report`,
    pushToERPForJaslok: `${apiUrl}/api/push/vendor`,
  },

  make: async function (url, method, params, auth, type) {
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    let authHeaders = {
      Authorization: getLocalToken(),
    };

    if (auth) {
      headers = {
        ...headers,
        ...authHeaders,
      };
    }

    if (method === "GET") {
      let queryParams = "";
      if (Object.keys(params || {}).length) {
        queryParams = encodeQueryData(params);
      }
      try {
        const response = await fetch(url + queryParams, {
          method,
          headers,
        });
        return type === "file" ? await response.blob() : await response.json();
      } catch (e) {
        return e;
      }
    } else {
      try {
        const response_1 = await fetch(url, {
          method,
          headers: type === "file" ? authHeaders : headers,
          body: type === "file" ? params : JSON.stringify(params),
        });
        return await response_1.json();
      } catch (e_1) {
        return e_1;
      }
    }
  },

  get: function (url, params, auth, file) {
    return apiClient.make(url, "GET", params, auth, file);
  },

  post: function (url, params, auth, type) {
    return apiClient.make(url, "POST", params, auth, type);
  },

  put: function (url, params, auth) {
    return apiClient.make(url, "PUT", params, auth);
  },

  patch: function (url, params, auth, type) {
    return apiClient.make(url, "PATCH", params, auth, type);
  },

  delete: function (url, params, auth) {
    return apiClient.make(url, "DELETE", params, auth);
  },
};

export default apiClient;
