import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminTable from "../features/adminTable/adminTable";
import BankAccounts from "../features/bankDetails/bankAccounts";
import BankDetails from "../features/bankDetails/bankDetails";
import Dashboard from "../features/dashboard/dashboard";
import VendorRegistration from "../features/vendorRegistration/vendorRegistration";
import VendorRegistrationView from "../features/vendorRegistration/vendorview";
import VendorRegistrationEdit from "../features/vendorRegistration/vendorRegistrationEdit";
import AccountSettings from "../features/accountSettings/accountSettings";
import AdminOrders from "../features/adminOrders/adminOrders";
import AdminOrdersTable from "../features/adminOrders/adminOrdersTable";
import InvoiceModuleTable from "../features/invoiceModule/invoiceModuleTable";
import InvoiceModule from "../features/invoiceModule/invoiceModule";
import UserOrdersTable from "../features/userOrders/userOrdersTable";
import UserOrdersView from "../features/userOrders/userOrdersView";
import AdminInvoicesTable from "../features/adminInvoices/adminInvoicesTable";
import AdminInvoicesView from "../features/adminInvoices/adminInvoicesView";
import AdminOrdersView from "../features/adminOrders/adminOrdersView";
import InvoiceModuleView from "../features/invoiceModule/invoiceModuleView";
import CompanyTable from "../features/company/companyTable";
import ItemTable from "../features/item/itemTable";
import Company from "../features/company/company";
import Item from "../features/item/item";
import CompanyView from "../features/company/companyView";
import CompaniesUserTable from "../features/companiesUser/companiesUserTable";
import CompaniesUser from "../features/companiesUser/companiesUser";
import CompaniesUsersView from "../features/companiesUser/companiesUserView";
import OfficerTable from "../features/adminTable/officerTable";
import OfficerCreateVendor from "../features/OfficerCreateVendor/OfficerCreateVendor";
import AdminAuthorities from "../features/admin_authirities/AdminAuthorities";
import AddFinance from "../features/admin_authirities/AddFinance";
import Notification from "../features/notification/notification";
import AdminGrns from "../features/adminGrns/adminGrns";
import AdminGrnsTable from "../features/adminGrns/adminGrnsTable";
import InspectionModuleTable from "../features/inspectionModule/inspectionModuleTable";
import ScheduleModule from "../features/scheduleModule/scheduleModule";
import InspectionModule from "../features/inspectionModule/inspectionModule";
import UserSchedulesTable from "../features/userSchedules/userSchedulesTable";
import AdminAsnsTable from "../features/adminAsns/adminAsnsTable";
import AdminSchedulesTable from "../features/adminSchedules/adminSchedulesTable";
import AsnModuleTable from "../features/asnModule/asnModuleTable";
import UserGrnsTable from "../features/userGrns/userGrnsTable";
import AsnModule from "../features/asnModule/asnModule";
import LotModule from "../features/lotModule/lotModule";
import UserLotsView from "../features/userLots/userLotsView";
import UserLotsEdit from "../features/userLots/userLotsEdit";
import AsnModuleView from "../features/asnModule/asnModuleView";
import UserSchedulesView from "../features/userSchedules/userSchedulesView";
import AdminSchedulesView from "../features/adminSchedules/adminSchedulesView";
import AdminLotsView from "../features/adminLots/adminLotsView";
import AdminAsnsView from "../features/adminAsns/adminAsnsView";
import AdminGrnsView from "../features/adminGrns/adminGrnsView";
import UserGrnsView from "../features/userGrns/userGrnsView";
import InspectionModuleView from "../features/inspectionModule/inspectionModuleView";
import Configuration from "../features/configuration/configurationList";
import UserPrivileges from "../features/userPrivileges/userPrivileges";
import PRCreationTableView from "../features/userPrivileges/PRCreationTableView";
import AdminPrs from "../features/adminPrs/adminPrs";
import AdminCopyPrs from "../features/adminPrs/adminCopyPrs";
import AdminPrsTable from "../features/adminPrs/adminPrsTable";
import UserWorkflowsView from "../features/workflows/userWorkflowsView";
import AdminPrsView from "../features/adminPrs/adminPrsView";
import AdminPrsPushView from "../features/adminPrs/adminPrsPushView";
import Department from "../features/departments/Department";
import DepartmentContent from "../features/departments/DepartmentContent";
import WorkflowTableView from "../features/workflows/WorkflowTableView";
import WorkflowSteps from "../features/workflows/WorkflowSteps";
import AdminItemsView from "../features/adminPrs/adminItemsView";
import WorkflowContent from "../features/workflows/WorkflowContent";
import AdminPrsPreview from "../features/adminPrs/adminPrsPreview";
import TenantView from "../features/support_role_screen/tenant/tenantView";
import TenantEdit from "../features/support_role_screen/tenant/tenantEdit";
import UserTable from "../features/support_role_screen/User/userTable";
import UserDetails from "../features/support_role_screen/User/userDetails";
import UserEdit from "../features/support_role_screen/User/userEdit";
import UserAdd from "../features/support_role_screen/User/userAdd";
import CategoryTable from "../features/vendor_category/category_table";
import CategoryAdd from "../features/vendor_category/categoryAdd";

export default function AppRouter() {
  return (
    <Routes>
      <Route index element={<Dashboard />} />
      <Route path="vendor-registration" element={<VendorRegistration />} />
      <Route path="bank-details/add-bank" element={<BankDetails />} />
      <Route path="bank-details/edit-bank/:id" element={<BankDetails />} />
      <Route path="bank-details" element={<BankAccounts />} />
      <Route path="account-settings" element={<AccountSettings />} />
      <Route
        path="vendor-registration-view/:id"
        element={<VendorRegistrationView />}
      />
      <Route
        path="vendor-registration-edit/:id"
        element={<VendorRegistrationEdit />}
      />
      <Route path="recomm-vcc" element={<AdminTable />} />
      <Route path="vendor" element={<OfficerTable />} />
      <Route path="orders" element={<AdminOrders />} />
      <Route path="lots" element={<LotModule />} />
      <Route path="orders-list" element={<AdminOrdersTable />} />
      <Route path="invoices-list" element={<AdminInvoicesTable />} />
      <Route path="invoices" element={<InvoiceModule />} />
      <Route path="company" element={<CompanyTable />} />
      <Route path="item" element={<ItemTable />} />
      <Route path="add-company" element={<Company />} />
      <Route path="add-item" element={<Item />} />
      <Route path="company/:company" element={<CompanyView />} />
      <Route path="users" element={<CompaniesUserTable />} />
      <Route path="add-user" element={<CompaniesUser />} />
      <Route path="users/:user" element={<CompaniesUsersView />} />
      <Route path="user-invoices-list" element={<InvoiceModuleTable />} />
      <Route path="user-orders-list" element={<UserOrdersTable />} />
      <Route path="add-vendor" element={<OfficerCreateVendor />} />
      <Route path="authorities" element={<AdminAuthorities />} />
      <Route path="add-finance" element={<AddFinance />} />
      <Route path="notification" element={<Notification />} />
      <Route
        path="user-orders-list-view/:orderid"
        element={<UserOrdersView />}
      />
      <Route
        path="invoices-list-view/:invoiceid"
        element={<AdminInvoicesView />}
      />
      <Route path="orders-list-view/:orderid" element={<AdminOrdersView />} />
      <Route
        path="user-invoices-list-view/:invoiceid"
        element={<InvoiceModuleView />}
      />
      <Route path="asns" element={<AsnModule />} />
      <Route path="grns" element={<AdminGrns />} />
      <Route path="grns-list" element={<AdminGrnsTable />} />
      <Route path="asns-list" element={<AdminAsnsTable />} />
      <Route path="schedules-list" element={<AdminSchedulesTable />} />
      <Route path="schedules" element={<ScheduleModule />} />
      <Route path="user-inspections-list" element={<InspectionModuleTable />} />
      <Route path="inspections" element={<InspectionModule />} />
      <Route path="user-schedules-list" element={<UserSchedulesTable />} />{" "}
      <Route path="user-asns-list" element={<AsnModuleTable />} />
      <Route path="user-grns-list" element={<UserGrnsTable />} />
      <Route path="asns-list-view/:asnid" element={<AdminAsnsView />} />
      <Route
        path="schedules-list-view/:scheduleid"
        element={<AdminSchedulesView />}
      />
      <Route
        path="user-schedules-list-view/:orderid"
        element={<UserSchedulesView />}
      />
      <Route path="user-lots-list-edit/:lotid" element={<UserLotsEdit />} />
      <Route path="user-lots-list-view/:lotid" element={<UserLotsView />} />
      <Route path="user-asns-list-view/:asnid" element={<AsnModuleView />} />
      <Route path="user-grns-list-view/:grnid" element={<UserGrnsView />} />
      <Route path="lots-list-view/:lotid" element={<AdminLotsView />} />
      <Route path="grns-list-view/:grnid" element={<AdminGrnsView />} />
      <Route
        path="user-inspections-list-view/:inspectionid"
        element={<InspectionModuleView />}
      />
      <Route path="configurations" element={<Configuration />} />
      <Route path="user-privileges" element={<UserPrivileges />} />
      <Route
        path="user-privileges/pr-creation"
        element={<PRCreationTableView />}
      />
      <Route path="prs" element={<AdminPrs />} />
      <Route path="copy-pr/:prid" element={<AdminCopyPrs />} />
      <Route path="prs-list" element={<AdminPrsTable />} />
      <Route path="departments" element={<Department />} />
      <Route path="create-department" element={<DepartmentContent />} />
      <Route path="workflow-steps" element={<WorkflowTableView />} />
      <Route
        path="user-workflows-list-view/:workflowid"
        element={<UserWorkflowsView />}
      />
      <Route
        path="add-workflows-steps/:workflowid"
        element={<WorkflowSteps />}
      />
      <Route path="prs-list-view/:prid" element={<AdminPrsView />} />
      <Route path="prs-push-view/:prid" element={<AdminPrsPushView />} />
      <Route path="items-list-view/:itemid" element={<AdminItemsView />} />
      <Route path="create-workflow" element={<WorkflowContent />} />
      <Route path="prs/preview/:id" element={<AdminPrsPreview />} />
      {/* vendor category pages */}
      <Route path="vendor_category" element={<CategoryTable />} />
      <Route path="vendor_category/add" element={<CategoryAdd />} />
      {/* support screens */}
      <Route path="support/tenant" element={<TenantView />} />
      <Route path="support/tenant/edit" element={<TenantEdit />} />
      <Route path="support/users/list" element={<UserTable />} />
      <Route path="support/users/:id" element={<UserDetails />} />
      <Route path="support/users/edit/:id" element={<UserEdit />} />
      <Route path="support/users/add" element={<UserAdd />} />
    </Routes>
  );
}
